import React from 'react';
import { LogInForm } from 'ui/component/log-in-form';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { useDesignConfig } from '@silkpwa/module/design-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

const LogInPage = () => {
    const t = usePhraseTranslater();
    const designConfig = useDesignConfig();
    const documentTitle = `${designConfig.pageTitle}${t(' Log In')}`;
    return (
        <React.Fragment>
            <DocumentTitle>{documentTitle}</DocumentTitle>
            <LogInForm />
        </React.Fragment>
    );
};

export { LogInPage as default };
